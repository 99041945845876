import React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Helmet from 'react-helmet'
import count from 'tcweb-material-components/core/poly/count'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'
import Box from 'tcweb-material-components/core/box'
import orderBy from 'lodash/orderBy'
import { makeStyles } from '@material-ui/core/styles'
import brandPortalBottomNav from '../assets/images/backgrounds/brand-portal/brand_portal_bg_bottom_nav.jpg'

import PageControls from '../components/common/pageControls'
import PageChildNavigation from '../components/common/pageChildNavigation'
import TableOfContents from '../components/common/tableOfContents'
import LayoutContent from '../components/layout/layoutContent'
import Page from '../components/templates/page'
import options from './components/'
import { decodeText } from '../utilities/helpers'
import { defaultWPMLLocale } from '../utilities/locale'

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    bottomNav: {
        background: `url(${brandPortalBottomNav}) bottom`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    pageWrapper: { width: '100%' } // For Firefox
})

const getChildPageSettings = (page) => {
    const currentPageHasNav = get(page, 'childTabs.hasChildPageNavigation')
    const parentPageHasNav = get(page, 'wpParent.node.childTabs.hasChildPageNavigation')

    const currentPageHasParentLink = get(page, 'childTabs.pageNavIncludeParentLink')
    const parentPageHasParentLink = get(page, 'wpParent.node.childTabs.pageNavIncludeParentLink')

    const currentPageParentLinkLabel = get(page, 'childTabs.pageNavParentLinkLabel')
    const parentPageParentLinkLabel = get(page, 'wpParent.node.childTabs.pageNavParentLinkLabel')

    // [hasChildNav, includeParentLink, parentLinkLabel]
    return [
        currentPageHasNav || parentPageHasNav,
        currentPageHasParentLink || parentPageHasParentLink,
        ( currentPageParentLinkLabel || parentPageParentLinkLabel || '' ).trim()
    ]
}

const PageTemplate = ({ isDraft, ...props }) => {
    const _classes = useClasses()
    const { t } = useTranslation()

    const title = get(props, 'data.page.title')
    const parentTitle = get(props, 'data.page.wpParent.node.title')
    const content = get(props, 'data.page.content')
    const excerpt = get(props, 'data.page.excerpt', '') // No longer supported in WP v6
    const path = get(props, 'path')
    const parentPath = get(props, 'data.page.wpParent.node.uri')
    const englishNode = find(i => get(i, 'locale.locale') === defaultWPMLLocale, get(props, 'data.page.translated', []))

    // TOC
    const hasToc = get(englishNode, 'toc.hasTableOfContents', get(props, 'data.page.toc.hasTableOfContents'))
    const tocItems = orderBy(get(props, 'pageContext.tableOfContents'), ['order', 'date'], ['asc', 'asc'])
    const numTocItems = count(tocItems)
    const showToc = hasToc && numTocItems > 0
    const pageControls = get(props, 'pageContext.pageControls', {})
    const fullPageControls = get(props, 'pageContext.fullPageControls', {})
    const hasFullPageControls = (fullPageControls.prev || fullPageControls.next) && parentPath

    // Child Pages
    const [ hasChildNav, includeParentLink, parentLinkLabel ] = !!englishNode ?
        getChildPageSettings(englishNode) :
        getChildPageSettings(get(props, 'data.page'))
    const isChildPage = !!get(props, 'data.page.wpParent.node')
    const childPages = isChildPage ?
        orderBy(get(props, 'data.page.wpParent.node.wpChildren.nodes'), ['menuOrder', 'date'], ['asc', 'asc']) :
        orderBy(get(props, 'data.page.wpChildren.nodes'), ['menuOrder', 'date'], ['asc', 'asc'])
    const showChildPages = hasChildNav && count(childPages) > 1

    if (showToc && !includeParentLink) {
        tocItems.shift()
    } else if (showToc && parentLinkLabel.length) {
        tocItems[0].title = t(parentLinkLabel)
    }

    // Add parent link as first link in navbar
    if (includeParentLink) {
        childPages.unshift({
            title: parentLinkLabel,
            uri: parentPath ? parentPath : path
        })
    }

    const cleanTitle = parentTitle
        ? `${props.data.site.siteMetadata.title} | ${decodeText(parentTitle)} | ${decodeText(props.data.page.title)}`
        : `${props.data.site.siteMetadata.title} | ${decodeText(props.data.page.title)}`
    const cleanExcerpt = (!!excerpt && decodeText(excerpt)) || ''

    return (
        <LayoutContent {...props} mainClass='wp-content' setBackgroundImage={showToc}>
            <Helmet
                title={cleanTitle}
                meta={[
                    // Twitter Card Data
                    { name: 'twitter:title', content: title },
                    { name: 'twitter:image:alt', content: get(props, 'data.page.featured_media.alt_text') || get(props, 'data.site.siteMetadata.socialAlt') },
                    { name: 'twitter:description', content: cleanExcerpt },
                    { property: 'og:title', content: title },
                    get(props, 'data.page.featuredImage') ? {
                        name: 'image',
                        property: 'og:image',
                        content: `${get(props, 'data.site.siteMetadata.siteUrl')}${get(
                            props,
                            'data.page.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src'
                        )}`
                    } : {},
                    { property: 'og:image:alt', content: get(props, 'data.page.featured_media.alt_text') || get(props, 'data.site.siteMetadata.socialAlt') },
                    { property: 'og:description', content: cleanExcerpt }
                ]}
            />
            {showChildPages && <PageChildNavigation items={childPages} isDraft={isDraft} />}
            {showToc ? (
                <Box className={_classes.root}>
                    <div className={_classes.pageWrapper}>
                        <Page meta={props.data.page.acf} setBackgroundImage={false}>
                            {parse(content, options)}
                        </Page>
                        <Box gutters='page'>
                            <PageControls
                                {...pageControls}
                                marginTop={{ xs: '30px', md: '50px' }}
                                marginBottom={{ xs: '30px', md: '100px' }}
                                enableTotal={false}
                                ariaLabelNext={t('Go to next page')}
                                ariaLabelPrev={t('Go to previous page')}
                            />
                        </Box>
                    </div>
                    <TableOfContents items={tocItems} isDraft={isDraft} />
                </Box>
            ) : hasFullPageControls ? (
                <>
                    <Page meta={props.data.page.acf} gutters='page'>{parse(content, options)}</Page>
                    <Box gutters='page' className={_classes.bottomNav}>
                        <PageControls
                            {...fullPageControls}
                            enableTotal={false}
                            ariaLabelNext={t('Go to next page')}
                            ariaLabelPrev={t('Go to previous page')}
                        />
                    </Box>
                </>
            ) : (
                <Page meta={props.data.page.acf} gutters='page'>{parse(content, options)}</Page>
            )}
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query($id: String) {
        page: wpSitePage(id: { eq: $id }) {
            title
            content
            toc {  hasTableOfContents }
            fulltoc { hasFullTableOfContents }
            childTabs {
                hasChildPageNavigation
                pageNavIncludeParentLink
                pageNavParentLinkLabel
            }
            wpParent {
                node {
                    ... on WPSitePage {
                        uri
                        title
                        childTabs {
                            hasChildPageNavigation
                            pageNavIncludeParentLink
                            pageNavParentLinkLabel
                        }
                        wpChildren {
                            nodes {
                                ... on WPSitePage {
                                    title
                                    uri
                                    menuOrder
                                }
                            }
                        }
                    }
                }
            }
            wpChildren {
                nodes {
                    ... on WPSitePage {
                        title
                        uri
                        menuOrder
                    }
                }
            }
            # excerpt # No longer supported in WP v6
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
            translated {
                title
                content
                locale { locale }
                toc {  hasTableOfContents }
                childTabs {
                    hasChildPageNavigation
                    pageNavIncludeParentLink
                    pageNavParentLinkLabel
                }
                wpParent {
                    node {
                        ... on WPSitePage {
                            childTabs {
                                hasChildPageNavigation
                                pageNavIncludeParentLink
                                pageNavParentLinkLabel
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                socialAlt
                siteUrl
            }
        }
    }
`

export default PageTemplate
